import React from "react";
import { Container } from "react-bootstrap";
import "../SCSS/_about.scss";
import Logo from "../Assets/PNG/Logo.png";

function About() {
  return (
    <Container className="about-container">
      <Container className="seprator-left"></Container>
      <Container className="profile-container">
        <h1>Profile</h1>
        <p>
          SK Construction is a leading construction and execution company with a
          reputation for excellence, quality, and commitment to our clients.
          With years of experience in the construction industry, we have built a
          team of skilled builders, engineers, architects, and project managers
          who are dedicated to providing innovative and sustainable solutions
          for all your construction needs.
          <br />
          <br />
          Our client-centered approach means that we work collaboratively with
          our clients to ensure that their vision is brought to life. We take
          the time to listen to their needs, provide clear communication, and
          create personalized plans that meet their unique requirements. We
          believe that communication is key, and we work closely with our
          clients to ensure that their project is completed on time, within
          budget, and to their exact specifications.
          <br />
          <br />
          We pride ourselves on our attention to detail, superior craftsmanship,
          and our ability to deliver exceptional results. Our commitment to
          excellence is evident in every project we undertake, and we strive to
          exceed our clients' expectations with every build.
          <br />
          <br />
          We understand the importance of sustainability and environmental
          responsibility, and we incorporate these principles into every project
          we undertake. Our innovative and sustainable construction practices
          reduce waste, conserve energy, and create a greener future.
          <br />
          <br />
          At SK Construction, we are passionate about building, and we are
          committed to delivering excellence in every project we undertake. Let
          us help you turn your vision into reality. Choose SK Construction for
          excellence in every build.
        </p>
      </Container>
      <Container className="seprator-right"></Container>
      <Container className="product-and-services-container">
        <h1>Services</h1>
        <p>
          We are committed to deliver the best and economical services without
          compromising on the reliability of performance.
        </p>

        <span>Some of them are follows:</span>

        <ul>
          <li>Construction Execution</li>
          <li>Structural Designing</li>
          <li>Architectural Designing</li>
          <li>Structural Audit</li>
          <li>Estimation and Costing</li>
        </ul>
      </Container>
      <Container className="seprator-left"></Container>
      <Container className="end-logo">
        <img src={Logo} alt="img" />
      </Container>
      <Container className="seprator-right"></Container>
    </Container>
  );
}

export default About;
