import React from "react";
import "../SCSS/_projects.scss";
import projectimg1 from "../Assets/Images/p1.jpg";

import { Col, Container, Row } from "react-bootstrap";

function Projects() {
  const images = [
    {
      image: projectimg1,
      title: "Bunglow",
      cost: "₹ 75 Lakh",
      address: "Arjuni",
    },

    {
      image: projectimg1,
      title: "Bunglow",
      cost: "₹ 75 Lakh",
      address: "Arjuni",
    },

    {
      image: projectimg1,
      title: "Bunglow",
      cost: "₹ 75 Lakh",
      address: "Arjuni",
    },

    {
      image: projectimg1,
      title: "Bunglow",
      cost: "₹ 75 Lakh",
      address: "Arjuni",
    },

    {
      image: projectimg1,
      title: "Bunglow",
      cost: "₹ 75 Lakh",
      address: "Arjuni",
    },
  ];
  return (
    <Container className="projects-container">
      <Row className="projects-row">
        {images.map((e) => {
          return (
            <Col md="6" className="projects-items">
              <img src={e.image} alt="img" />
              <h3>{e.title}</h3>
              <p>Project Cost : {e.cost}</p>
              <span>{e.address}</span>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default Projects;
