import React from "react";
import "../SCSS/_footer.scss";

import { Container, Row, Col } from "react-bootstrap";
import Logo from "../Assets/PNG/Asset 2@3x.png";
import { MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <Container className="socials-container">
        <Container className="socials-items">
          <MDBBtn floating className="m-1" href="#!" role="button">
            <MDBIcon fab icon="whatsapp" />
          </MDBBtn>

          <MDBBtn floating className="m-1" href="#!" role="button">
            <MDBIcon fab icon="facebook-f" />
          </MDBBtn>

          <MDBBtn floating className="m-1" href="#!" role="button">
            <MDBIcon fab icon="instagram" />
          </MDBBtn>

          <MDBBtn floating className="m-1" href="#!" role="button">
            <MDBIcon fab icon="twitter" />
          </MDBBtn>
        </Container>
      </Container>

      <div className="main-footer-container">
        <Row>
          <Col md="6" className="logo-container">
            <img src={Logo} alt="img" />
          </Col>

          <Col md="6">
            <Row>
              <Col className="footer-links-container">
                <h2>Useful Links</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </Col>

              <Col className="footer-links-container">
                <h2>Products</h2>
                <ul>
                  <li>
                    <Link to="/projects">Projects</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div className="copyright-container">
        <p>© 2023 Copyright: www.skconstruction.in</p>
      </div>
    </div>
  );
}

export default Footer;
