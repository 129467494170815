import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heroimg from "../Assets/SVG/Heroimg.svg";
import "../SCSS/_home.scss";
import { Link } from "react-router-dom";
import projectimg1 from "../Assets/Images/p1.jpg";

import serviceimg from "../Assets/Images/3.png";
import trustimg from "../Assets/PNG/trust.png";
import callimg from "../Assets/PNG/call.png";
import moneyimg from "../Assets/PNG/money.png";
import teamimg from "../Assets/PNG/team.png";
import Contact from "./Contact";
import Logo from "../Assets/PNG/Logo.png";
import Accordion from "react-bootstrap/Accordion";

function Home() {
  const data = [
    {
      image: projectimg1,
      title: "Bunglow",
      cost: "₹ 75 Lakh",
      address: "Arjuni",
    },
  ];

  return (
    <Container className="Home">
      <Row className="landing-page">
        <Col className="intro-container" md="6">
          <h1>Building a Stronger Tomorrow with Our Expertise Today.</h1>
          <p>
            At SK Construction, we understand that your construction project is
            not just about constructing a building or structure, but it's about
            bringing your vision to life. With years of experience in the
            construction and execution industry, we have built a reputation for
            excellence, quality, and commitment to our clients. Our team of
            skilled builders, engineers, architects, and project managers are
            dedicated to providing innovative and sustainable solutions for all
            your construction needs. From concept to completion, we work with
            you every step of the way to ensure that your project is completed
            on time, within budget, and to your exact specifications.
            <br />
            Let us help you turn your vision into reality with SK Construction.
            Experience excellence today.
          </p>

          <Link to="contact">Contact Us</Link>
        </Col>

        <Col md="6" className="hero-img-container">
          <img src={Heroimg} alt="img" />
        </Col>
      </Row>

      <Container className="seprator-left"></Container>

      <Container className="projects-container">
        <h1>Our Projects</h1>
        <p>
          At SK Construction, we have a diverse portfolio of successful projects
          that demonstrate our commitment to excellence in construction and
          execution. From commercial buildings to residential properties, we
          have the expertise and resources to take on any project, big or small.
          <br />
          Our projects range from simple renovations to complex builds, and we
          approach every project with the same level of dedication and attention
          to detail. We pride ourselves on our ability to complete projects on
          time, within budget, and to the exact specifications of our clients.
        </p>

        <Row className="project-list">
          {data.map((e) => {
            return (
              <Col md="6" className="project-list-items">
                <img src={e.image} alt="img" />
                <h3>{e.title}</h3>
                <p>Project Cost : {e.cost}</p>
                <span>{e.address}</span>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Container className="seprator-right"></Container>
      <Container className="services-container">
        <h1>Our Services</h1>
        <Row>
          <Col md="6" className="service-img-container">
            <img src={serviceimg} alt="img" />
          </Col>
          <Col md="6" className="service-txt-container">
            <p>
              Our team of expert builders, architects, and engineers are
              dedicated to providing innovative and sustainable solutions for
              all your construction needs. Whether it's a commercial or
              residential project, we are passionate about delivering
              exceptional results that exceed your expectations.
            </p>

            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <p>+ Construction Execution</p>
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>- Residential Building / Row houses</li>
                    <li>- Commercial buildings and Complex</li>
                    <li>- Warehouse / Industrial Structures</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <p>+ Structural Designing</p>
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>- Provide Structural Drawings</li>
                    <li>- Issue Design Basis Report</li>
                    <li>- Issue of Detailed Project Report</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <p>+ Architectural Designing</p>
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>- Residential Building / Row houses</li>
                    <li>- Commercial buildings and Complex</li>
                    <li>- Landscaping</li>
                    <li>- Warehouse / Industrial Structures</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <p>+ Structural Audit</p>
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>- Non Destructive Testing (NDT)</li>
                    <li>- Issues and Audit Report</li>
                    <li>- Repair and Rehabilitation Work</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <p>+ Estimation and Costing</p>
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>- Residential Building / Row houses</li>
                    <li>- Commercial buildings and Complex</li>
                    <li>- Landscaping</li>
                    <li>- Warehouse / Industrial Structures</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Container className="seprator-left"></Container>

      <Container className="why-us-container">
        <h1>Why Choose Us</h1>
        <p>
          With our expertise, commitment to excellence, and client-centered
          approach, we are your partner in success. Our team of skilled
          builders, engineers, architects, and project managers work
          collaboratively to ensure that every project we undertake is completed
          to the highest standards. Let us help you turn your vision into
          reality with our innovative and sustainable construction practices.
          Choose SK Construction for excellence in every build.
        </p>

        <Row className="value-container">
          <Col md="6" className="value-items">
            <img src={trustimg} alt="img" />
            <div className="value-text-container">
              <h2>Customer Satisfaction</h2>
              <p>
                We take pride in our ideal projects and ever impeccable service
                which keeps adding to our number of satisfied customers
                everyday.
              </p>
            </div>
          </Col>
          <Col md="6" className="value-items">
            <img src={callimg} alt="img" />
            <div className="value-text-container">
              <h2>Best support</h2>
              <p>
                Exceeding customer expectations by always being the first to
                market the best projects. Partnering with our customers to make
                sure they succeed.
              </p>
            </div>
          </Col>
          <Col md="6" className="value-items">
            <img src={moneyimg} alt="img" />
            <div className="value-text-container">
              <h2>Economic Friendly</h2>
              <p>
                We have team of highly skilled professionals who stringently
                inspect all services provided by us in the most affordable price
                possible.
              </p>
            </div>
          </Col>
          <Col md="6" className="value-items">
            <img src={teamimg} alt="img" />
            <div className="value-text-container">
              <h2>Expert team members</h2>
              <p>
                We have team of highly skilled professionals who stringently
                inspect all good and services provided by us in the most
                affordable price possible.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="seprator-right"></Container>

      <Contact />

      <Container className="seprator-left"></Container>

      <Container className="end-logo">
        <img src={Logo} alt="img" />
      </Container>
      <Container className="seprator-right"></Container>
    </Container>
  );
}

export default Home;
