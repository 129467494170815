import React from "react";
import { Container } from "react-bootstrap";
import Contact from "./Contact";

function ContactPage() {
  return (
    <Container style={{ padding: "0vh 4vh" }}>
      <Contact />
    </Container>
  );
}

export default ContactPage;
