import "../SCSS/_contact.scss";
import Emailjs from "emailjs-com";
import { Col, Container, Row } from "react-bootstrap";

function Contact() {
  function sendEmail(e) {
    e.preventDefault(e);
    Emailjs.sendForm(
      "service_rygs7fs",
      "template_9elm4aq",
      e.target,
      "n6Bg0Kuxl5lIAl0QA"
    )
      .then(() => {
        window.location.replace("/thankyou");
      })
      .catch((err) => console.log(err));
  }

  return (
    <Container className="contact-us-container">
      <h1>Contact Us</h1>
      <Row className="contact-row">
        <Col md="6" className="form-container">
          <form className="my-form" onSubmit={sendEmail}>
            <input type="text" name="name" placeholder="Name" required />
            <input type="text" name="surname" placeholder="Surname" required />
            <input type="email" name="email" placeholder="Email" required />
            <input type="tel" name="contact" placeholder="Phone" />
            <textarea
              name="message"
              className="message-input"
              placeholder="Message"
            ></textarea>
            <Container className="button-container">
              <input className="submit-button" type="submit" value="submit" />
            </Container>
          </form>
        </Col>
        <Col md="6">
          <Container className="address-container">
            <h1>Get in Touch</h1>
            <p>
              Reach us if you need any help or enquiry regarding the services
              that we provide.
            </p>
            <Container>
              <h2>Email</h2>
              <p>connect@skconstruction.in</p>
              <h2>Phone</h2>
              <p>+91 788 776 2989</p>
              <h2>Address</h2>
              <p>102,Renuka Jyoti Enclave, Jaitala, Nagpur</p>
            </Container>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
